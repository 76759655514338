import React from 'react';
import { LinkButton } from '../../../components';
import { LogoIcon } from '../../../assets';
import { HomeSectionScreenProps } from '../Home';
import './Welcome.scss';
import video from '../../../assets/images/mini/waves-gold-mini-v2.mp4';

type WelcomeProps = HomeSectionScreenProps & {
  nextScreenId: string;
};

export const Welcome: React.FC<WelcomeProps> = ({ screenId, nextScreenId }) => {
  return (
    <div className='welcome' id={screenId}>
      <video autoPlay loop muted playsInline preload='auto'>
        <source src={video} type='video/mp4' />
      </video>
      <div className='welcome__content'>
        <h3>Welcome to</h3>
        <img className='logo' src={LogoIcon} alt='C4Workx' />
        <div className='unveil'>
          <LinkButton
            text='Unveil our secret &#8595;'
            url={`#${nextScreenId}`}
            target='_self'
            font='big'
            fill='filled'
            color='primary'
          />
        </div>
      </div>
    </div>
  );
};
