import React from 'react';
import { HomeSectionScreenProps } from '../Home';
import './Companies.scss';

export const Companies: React.FC<HomeSectionScreenProps> = ({ screenId }) => {
  const companies = [
    'STARBUCKS COFFEE',
    'HALLIBURTON',
    'DIAMOND OFFSHORE',
    'MARATHON',
    'JPMC',
    'SECURITAS',
    'REACHAT',
    'ANA HEALTH',
    'SCI',
    'DURASENSE',
    'EXPRESS PROFESSIONAL',
    'EXXON',
    'XECTA',
    'HUMANGO',
    'FORTIFY IT',
    'MOONFLOWER FARMS',
    'SLB',
    'TENARIS',
    'QUANTUM ENERGY',
    'CONOCOPHILLIPS',
    'C4WORKX',
    'GE OIL AND GAS',
    'SQUATZ',
    'BAKER HUGHES',
    'CHEVRON',
    'BENJAMIN MOORE',
    'MD ANDERSON',
    'CHASE',
    'TELUS',
    'TUNECORE',
    'MICHELLIN',
    'METLIFE',
    'TOYOTA',
    'CADENCE BANK',
    'WEATHERFORD',
  ];

  function _getMiddleElement(): number {
    return Math.round(companies.length / 2);
  }

  function _getHalfCompanies(isFirstHalf: boolean): Array<string> {
    if (isFirstHalf) {
      return companies.slice(0, _getMiddleElement());
    } else {
      return companies.slice(_getMiddleElement());
    }
  }

  const _renderCompanies = (isFirstHalf: boolean) => {
    return _getHalfCompanies(isFirstHalf).map((company, index) => <p key={index}>{company}</p>);
  };

  return (
    <div className='companies' id={screenId}>
      <div className='companies__content'>
        <div className='names'>{_renderCompanies(true)}</div>
        <div>
          <h3>
            ACROSS ALL
            <br />
            <b>INDUSTRY</b> | <b>COMPANY</b> | <b>CHALLENGE.</b>
            <br />
          </h3>
          <h3>
            <span className='text-emphasis'>
              We work with <span className='highlight'>everyone.</span>
            </span>
          </h3>
        </div>
        <div className='names'>{_renderCompanies(false)}</div>
      </div>
    </div>
  );
};
