import { FC } from 'react';
import { LinkButton } from '../Button/LinkButton';
import { LogoCombinationMarkDarkImg, LogoCombinationMarkLightImg } from '../../assets';
import './Header.scss';
import { MenuButton } from 'components/MenuButton/MenuButton';

export type HeaderTheme = 'light' | 'dark';

type HeaderProps = {
  theme: HeaderTheme;
  showMobileNavigation: boolean;
  isMobileNavigationActive: boolean;
  letsTalkScreenId: string;
  onToggleMobileNavigation: () => void;
};

export const Header: FC<HeaderProps> = ({
  theme,
  showMobileNavigation,
  isMobileNavigationActive,
  letsTalkScreenId,
  onToggleMobileNavigation,
}) => {
  return (
    <header className={`${theme} ${isMobileNavigationActive ? 'mobile-nav-active' : 'mobile-nav-inactive'}`}>
      <a href='#home'>
        <img src={theme === 'light' ? LogoCombinationMarkLightImg : LogoCombinationMarkDarkImg} alt='C4Workx logo' />
      </a>
      {showMobileNavigation ? (
        <MenuButton theme={theme} isActive={isMobileNavigationActive} onClick={onToggleMobileNavigation} />
      ) : (
        <LinkButton
          text='Lets talk!'
          url={`#${letsTalkScreenId}`}
          target='_self'
          font='regular'
          fill='hollow'
          color={theme === 'light' ? 'white' : 'secondary'}
        />
      )}
    </header>
  );
};
