import React, { useEffect, useLayoutEffect, useState } from 'react';
import throttle from 'lodash.throttle';
import { Header, HeaderTheme } from '../../components/Header';
import { HomeNavSection, HomeNavTheme, HomeNav, MobileHomeNav } from '../../components/HomeNav';
import { Welcome } from './Welcome/Welcome';
import { WhatWeDo } from './WhatWeDo/WhatWeDo';
import { CompanyScope } from './CompanyScope/CompanyScope';
import { Mission } from './Mission/Mission';
import { Companies } from './Companies/Companies';
import { Challenges } from './Challenges/Challenges';
import { ServicesOffered } from './ServicesOffered/ServicesOffered';
import { Capabilities } from './Capabilities/Capabilities';
import { Differentials } from './Differentials/Differentials';
import { Founders } from './Founders/Founders';
import { ContactInfo } from './ContactInfo/ContactInfo';
import useWindowDimensions from '../../utils/WindowDimensions';
import './Home.scss';
import { cleanUrl, cleanUrlWithDelay } from '../../utils/cleanUrl';
import { OurSuperheroes } from './OurSuperheroes/OurSuperheroes';
import { DotIndicator } from '../../components/DotIndicator/DotIndicator';

export type HomeSectionScreenProps = {
  screenId: string;
};

export type HomeSectionInfo = HomeNavSection & {
  slideIndex: number;
};

// Nav
enum HomeSections {
  HOME = 'home',
  WHAT_WE_DO = 'what-we-do',
  BOUTIQUE_CONSULTANCY = 'boutique-consultancy',
  OUR_CHALLENGES = 'our-challenges',
  CAPABILITIES = 'capabilities',
  HOW_WE_DO_IT = 'how-we-do-it',
  FOUNDERS_AND_LEADERSHIP = 'founders-and-leadership',
  LETS_TALK = 'lets-talk',
}

const HomeSectionInfoList: Array<HomeSectionInfo> = [
  {
    name: 'HOME',
    id: HomeSections.HOME,
    slideIndex: 0,
  },
  {
    name: 'WHAT WE DO',
    id: HomeSections.WHAT_WE_DO,
    slideIndex: 1,
  },
  {
    name: 'OUR CHALLENGES',
    id: HomeSections.OUR_CHALLENGES,
    slideIndex: 5,
  },
  {
    name: 'CAPABILITIES',
    id: HomeSections.CAPABILITIES,
    slideIndex: 7,
  },
  {
    name: 'HOW WE DO IT',
    id: HomeSections.HOW_WE_DO_IT,
    slideIndex: 9,
  },
  {
    name: 'FOUNDERS & LEADERSHIP',
    id: HomeSections.FOUNDERS_AND_LEADERSHIP,
    slideIndex: 10,
  },
  {
    name: 'LETS TALK!',
    id: HomeSections.LETS_TALK,
    slideIndex: 11,
  },
];

export const Home: React.FC = () => {
  const { width } = useWindowDimensions();

  const mobileMaxWidth = 768;
  const darkThemmedHeaderIndexes = [1, 3, 4, 6];
  const lightThemmedNavIndexes = [0, 2, 5, 7, 9, 12];

  const [headerTheme, setHeaderTheme] = useState<HeaderTheme>('light');
  const [mobileNavActive, setMobileNavActive] = useState<boolean>(false);
  const [activeSection, setActiveSection] = useState<number>(0);
  const [navTheme, setNavTheme] = useState<HomeNavTheme>('light');
  const [showNav, setShowNav] = useState<boolean>(false);
  const [showMobileNavigation, setShowMobileNavigation] = useState<boolean>(false);

  useEffect(() => {
    setShowMobileNavigation(width <= mobileMaxWidth);
  }, [width]);

  useEffect(() => {
    // remove the anchor hash on load because it'll always start at first slide
    cleanUrl();
  }, []);

  useLayoutEffect(() => {
    const scrollSensitivitySetting = 30; // increase/decrease this number to change sensitivity to events (up = less sensitive; down = more sensitive)
    const slideLockInMilliseconds = 800; // amount of time for which scroll is "locked"
    let touchStartingY = 0;

    const slides = document.querySelectorAll('.home-slide') as NodeListOf<HTMLElement>;

    // set zIndex property in descending order, for paralax to work
    for (let index = 0; index < slides.length; index++) {
      slides[index].style.zIndex = (slides.length + 1 - index).toString();
    }

    let currentSlideNumber = 0;
    let movedUp = false;
    let locked = false;

    // set timeout to temporarily block further handling of scrolling events
    function lockTimeout() {
      locked = true;
      setTimeout(function () {
        locked = false;
      }, slideLockInMilliseconds);
      onSlideChanged(currentSlideNumber, movedUp);
    }

    function moveDown() {
      movedUp = false;
      const previousSlide = slides[currentSlideNumber];
      previousSlide.classList.remove('up-scroll');
      previousSlide.classList.add('down-scroll');
      currentSlideNumber++;
    }

    function moveUp() {
      movedUp = true;
      currentSlideNumber--;
      const currentSlide = slides[currentSlideNumber];
      currentSlide.classList.remove('down-scroll');
      currentSlide.classList.add('up-scroll');
    }

    function parallaxScroll(evt: WheelEvent) {
      if (locked) return;

      // determine how much scroll has moved
      const { deltaY } = evt;
      const isMovingDown = deltaY > scrollSensitivitySetting && currentSlideNumber !== slides.length - 1;
      const isMovingUp = deltaY < -scrollSensitivitySetting && currentSlideNumber !== 0;

      if (isMovingDown) {
        moveDown();
        lockTimeout();
      }

      if (isMovingUp) {
        moveUp();
        lockTimeout();
      }
    }

    function onHashChange() {
      const { hash } = window.location;

      if (!hash) return;

      const newHash = hash.replace('#', '');
      const bouticqueIndex = 2;
      let targetSlideIndex =
        newHash !== HomeSections.BOUTIQUE_CONSULTANCY
          ? HomeSectionInfoList.find(section => section.id === newHash)?.slideIndex
          : bouticqueIndex;

      if (targetSlideIndex !== undefined && currentSlideNumber !== targetSlideIndex) {
        const moveSlides = targetSlideIndex > currentSlideNumber ? moveDown : moveUp;
        const steps = Math.abs(targetSlideIndex - currentSlideNumber);

        for (let i = 0; i < steps; i++) {
          moveSlides();
        }
        lockTimeout();
      }
      cleanUrlWithDelay();
    }

    function onTouchStart(evt: TouchEvent) {
      touchStartingY = evt?.touches[0]?.pageY;
    }

    function parallaxScrollOnTouch(evt: TouchEvent) {
      if (locked) return;

      const touchDelta = evt?.touches[0]?.pageY - touchStartingY;
      const isMovingDown = touchDelta <= -scrollSensitivitySetting && currentSlideNumber !== slides.length - 1;
      const isMovingUp = touchDelta >= scrollSensitivitySetting && currentSlideNumber !== 0;

      if (isMovingDown) {
        moveDown();
        lockTimeout();
      }

      if (isMovingUp) {
        moveUp();
        lockTimeout();
      }
    }

    window.addEventListener('wheel', throttle(parallaxScroll, 60), false);
    window.addEventListener('touchstart', throttle(onTouchStart, 60), false);
    window.addEventListener('touchmove', throttle(parallaxScrollOnTouch, 60), false);
    window.addEventListener('hashchange', onHashChange, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onSlideChanged(currentSlide: number, movedUp: boolean) {
    setHeaderTheme(darkThemmedHeaderIndexes.includes(currentSlide) ? 'dark' : 'light');
    setNavTheme(lightThemmedNavIndexes.includes(currentSlide) ? 'light' : 'dark');
    // hide navbar at first and last screen
    setShowNav(currentSlide !== 0);
    let sectionIndex = HomeSectionInfoList.findIndex(section => section.slideIndex === currentSlide);
    if (sectionIndex !== -1) {
      setActiveSection(sectionIndex);
    } else if (movedUp) {
      let active: number = 0;

      for (let index = 1; index < HomeSectionInfoList.length; index++) {
        const curr = HomeSectionInfoList[index];
        if (curr.slideIndex > currentSlide) {
          break;
        }
        active = index;
      }

      setActiveSection(active);
    }
  }

  function toggleMobileNav() {
    setMobileNavActive(!mobileNavActive);
  }

  function renderNavbar() {
    if (showMobileNavigation) {
      return (
        <div className={`mobile-navbar-container ${mobileNavActive ? 'show' : 'hide'}`}>
          <MobileHomeNav
            theme={headerTheme}
            currentActiveSection={activeSection}
            sections={HomeSectionInfoList}
            letsTalkScreenId={HomeSections.LETS_TALK}
            onClickAtSection={toggleMobileNav}
          />
        </div>
      );
    } else {
      return (
        <div className={`navbar-container ${showNav ? 'show' : 'hide'}`}>
          <HomeNav theme={navTheme} currentActiveSection={activeSection} sections={HomeSectionInfoList} />
        </div>
      );
    }
  }

  return (
    <div>
      <section className='home-slide'>
        <div className='content-wrapper'>
          <Welcome screenId={HomeSections.HOME} nextScreenId={HomeSections.WHAT_WE_DO} />
        </div>
      </section>
      <section className='home-slide'>
        <div className='content-wrapper'>
          <Mission screenId={HomeSections.WHAT_WE_DO} nextScreenId={HomeSections.BOUTIQUE_CONSULTANCY} />
        </div>
      </section>
      <section className='home-slide'>
        <div className='content-wrapper'>
          <WhatWeDo screenId={HomeSections.BOUTIQUE_CONSULTANCY} />
        </div>
      </section>
      <section className='home-slide'>
        <div className='content-wrapper'>
          <CompanyScope />
        </div>
      </section>
      <section className='home-slide'>
        <div className='content-wrapper'>
          <OurSuperheroes />
        </div>
      </section>
      <section className='home-slide'>
        <div className='content-wrapper'>
          <Companies screenId={HomeSections.OUR_CHALLENGES} />
        </div>
      </section>
      <section className='home-slide'>
        <div className='content-wrapper'>
          <Challenges />
        </div>
      </section>
      <section className='home-slide'>
        <div className='content-wrapper'>
          <ServicesOffered screenId={HomeSections.CAPABILITIES} />
        </div>
      </section>
      <section className='home-slide'>
        <div className='content-wrapper'>
          <Capabilities />
        </div>
      </section>
      <section className='home-slide'>
        <div className='content-wrapper'>
          <Differentials screenId={HomeSections.HOW_WE_DO_IT} />
        </div>
      </section>
      <section className='home-slide'>
        <div className='content-wrapper'>
          <Founders screenId={HomeSections.FOUNDERS_AND_LEADERSHIP} />
        </div>
      </section>
      <section className='home-slide'>
        <div className='content-wrapper'>
          <ContactInfo screenId={HomeSections.LETS_TALK} />
        </div>
      </section>
      <div className='header-container'>
        <Header
          theme={headerTheme}
          showMobileNavigation={showMobileNavigation}
          isMobileNavigationActive={mobileNavActive}
          letsTalkScreenId={HomeSections.LETS_TALK}
          onToggleMobileNavigation={toggleMobileNav}
        />
      </div>
      {renderNavbar()}
      {showNav && <DotIndicator sections={HomeSectionInfoList} currentSection={activeSection} />}
    </div>
  );
};
