import React, { useState } from 'react';
import { CapabilityCard, Slider } from '../../../components';
import { BoxIcon, ChartIcon, TargetIcon } from '../../../assets';
import useWindowDimensions from '../../../utils/WindowDimensions';
import './Capabilities.scss';

type Capability = {
  icon: string;
  field: string;
  title: string;
  subtitle: string;
  description: string;
  solution: string;
};

export const Capabilities: React.FC = () => {
  const _sliderMinWindowWidth = 1024;
  const _capabilities: Array<Capability> = [
    {
      icon: ChartIcon,
      field: 'DELIVER',
      title: 'PROGRAM MANAGEMENT',
      subtitle: 'COACH | MANAGE',
      description:
        'A goal without a plan is just a wish – let us help you deliver the projects and programs you promised. We excel at Agile, DevOps, program recovery, project setup, team motivation, team creation and in-depth planning.',
      solution: 'We come, we see, we control and drive delivery.',
    },
    {
      icon: TargetIcon,
      field: 'DEFINE',
      title: 'CONCEPTUALIZATION',
      subtitle: 'CONCEPT | DESIGN',
      description:
        'Bring your vision to life with a unique “greatest hits” of business strategy, innovation, behavior science, design thinking, and more—packaged into a proven process that any team can use and follow.',
      solution: 'We get real answers with real outcomes in record time.',
    },
    {
      icon: BoxIcon,
      field: 'MEASURE & BUILD',
      title: 'ENGINEERING',
      subtitle: 'ASSIST | SOLVE | DO',
      description:
        'A full spectrum of engineering capability, leveraging the power of the cloud, custom application development using modern web frameworks, mobile applications, DevOps automation and data & analytics.',
      solution: 'We engineer what it takes to reach outcomes.',
    },
  ];

  const [_activeCard, _setActiveCard] = useState<number>(1);
  const [_dismissedCard, _setDismssedCard] = useState<number>();

  const { width } = useWindowDimensions();

  function _renderCapabilities() {
    if (width <= _sliderMinWindowWidth) {
      return _renderCapabilityCardsSlider();
    } else {
      return _renderCapabilityCardsList();
    }
  }

  function _renderCapabilityCardsList() {
    return _capabilities.map((capability, index) => {
      return (
        <div className='capability' key={index} onMouseEnter={() => _activateCard(index)}>
          <div
            className={`card ${index === _activeCard ? 'active' : ''} ${index === _dismissedCard ? 'dismissed' : ''}`}>
            <CapabilityCard
              iconScr={capability.icon}
              field={capability.field}
              title={capability.title}
              subtitle={capability.subtitle}
              description={capability.description}
              solution={capability.solution}
            />
          </div>
        </div>
      );
    });
  }

  function _renderCapabilityCardsSlider() {
    return (
      <Slider<Capability>
        description='capability'
        list={_capabilities}
        initialSlideIndex={1}
        renderSlide={(capability, index) => _renderCapabilityCard(capability, index)}
      />
    );
  }

  function _renderCapabilityCard(capability: Capability, index: number) {
    return (
      <div className='capability' key={index}>
        <CapabilityCard
          iconScr={capability.icon}
          field={capability.field}
          title={capability.title}
          subtitle={capability.subtitle}
          description={capability.description}
          solution={capability.solution}
        />
      </div>
    );
  }

  function _activateCard(index: number) {
    if (index !== _activeCard) {
      _setDismssedCard(_activeCard);
      _setActiveCard(index);
    }
  }

  return (
    <div className='capabilities'>
      <div className='capabilities__content'>
        <div className='capabilities__header'>
          <h3>
            Our <b>capabilities</b>
          </h3>
        </div>
        <div className='capabilities__list'>
          <div className='capabilities__list__content'>{_renderCapabilities()}</div>
        </div>
      </div>
    </div>
  );
};
