import { FC } from 'react';
import './DotIndicator.scss';
import { HomeNavSection } from '../HomeNav';

type DotIndicatorProps = {
  sections: HomeNavSection[];
  currentSection: number;
};

type Size = `size${0 | 1 | 2 | 3 | 4 | 5}`;

const getSize = (distance: number): Size => {
  return `size${Math.min(distance, 5)}` as Size;
};

export const DotIndicator: FC<DotIndicatorProps> = ({ currentSection, sections }) => {
  return (
    <div className='dot-indicator'>
      {sections.map((_, id) => {
        const size = getSize(Math.abs(id - currentSection));

        return <div key={id} className={id === currentSection ? `dot active ${size}` : `dot ${size}`} />;
      })}
    </div>
  );
};
