import React, { JSX } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '../../assets';
import './Slider.scss';

type SliderProps<T> = {
  description: string;
  list: Array<T>;
  initialSlideIndex: number;
  renderSlide: (element: T, index: number) => JSX.Element;
};

/*
The component currently supports a maximum of 5 slides. 
To add more slides it's necessary to update the scss file.
*/
export function Slider<T>({ description, list, initialSlideIndex, renderSlide }: SliderProps<T>) {
  function _renderInputs() {
    return list.map((_, index) => (
      <input
        key={index}
        type='radio'
        id={`control-${description}-${index + 1}`}
        name={`control-slide-${description}`}
        defaultChecked={index === initialSlideIndex}
      />
    ));
  }

  function _renderNavigators() {
    let lastSlide = list.length;
    return list.map((_, index) => {
      let slide = index + 1;
      let previousSlide = slide === 1 ? lastSlide : slide - 1;
      let nextSlide = slide === lastSlide ? 1 : slide + 1;
      return (
        <div className={`navigator slide-${slide}`} key={index}>
          <label htmlFor={`control-${description}-${previousSlide}`}>
            <img src={ChevronLeftIcon} alt='previous' />
          </label>
          <label htmlFor={`control-${description}-${nextSlide}`}>
            <img src={ChevronRightIcon} alt='next' />
          </label>
        </div>
      );
    });
  }

  function _renderSlides() {
    return list.map((element, index) => (
      <li className='slide' key={index}>
        {renderSlide(element, index)}
      </li>
    ));
  }

  return (
    <div className='slider'>
      <ul className='slides'>
        {_renderInputs()}
        {_renderNavigators()}
        {_renderSlides()}
      </ul>
    </div>
  );
}
