import { HeaderTheme } from 'components/Header';
import { FC } from 'react';
import './MenuButton.scss';

type MenuButtonProps = {
  theme: HeaderTheme;
  isActive: boolean;
  onClick: () => void;
};

export const MenuButton: FC<MenuButtonProps> = ({ isActive, onClick, theme }) => {
  return (
    <div className={`mobile-nav-control ${isActive ? 'active' : 'inactive'}`} onClick={onClick}>
      <span className={theme} />
    </div>
  );
};
