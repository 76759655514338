import { FC, useState } from 'react';
import { OurSuperhearosImg } from '../../../assets';
import './OurSuperheroes.scss';

export const OurSuperheroes: FC = () => {
  const [isRight, setIsRight] = useState(false);

  const onClick = () => {
    setIsRight(!isRight);
  };

  return (
    <div className='our-hearos'>
      <div className='our-hearos__content'>
        <div className='peoples' onClick={onClick}>
          <img className={isRight ? 'peoples-img right' : 'peoples-img'} src={OurSuperhearosImg} alt='our people' />
        </div>
        <div className='texts'>
          <div className='texts__content'>
            <h5>#WeAreDynamite</h5>
            <h3>
              Our power is
              <br />
              <span className='text-emphasis'>our superheroes</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};
