import React from 'react';
import { FounderCard, FounderCardProps, Slider } from '../../../components';
import { PhuongPhanImg, RegisGontierImg, NadeemHaqImg } from '../../../assets/images/founders';
import { HomeSectionScreenProps } from '../Home';
import useWindowDimensions from '../../../utils/WindowDimensions';
import './Founders.scss';

export const Founders: React.FC<HomeSectionScreenProps> = ({ screenId }) => {
  const _sliderMinWindowWidth = 1024;
  const { width } = useWindowDimensions();

  const _founders: Array<FounderCardProps> = [
    {
      name: 'Regis Gontier',
      imageScr: RegisGontierImg,
      role: 'FOUNDER',
      roleDescription: 'PEOPLE & DELIVERY',
      profileUrl: 'https://www.linkedin.com/in/regis-gontier-33b33411/',
      description:
        "Achieving successful project completion isn't a matter of luck. It requires thorough planning, consistent monitoring at regular intervals, and Project Stakeholders involvement.",
    },
    {
      name: 'Phuong C. Phan',
      imageScr: PhuongPhanImg,
      role: 'FOUNDER',
      roleDescription: 'GROWTH & STRATEGY',
      profileUrl: 'https://www.linkedin.com/in/phuong-chau-phan/',
      description:
        'Most of the consulting companies out there have either creative excellence or good developers. At C4Works, we create a culture that captivated top 1% talents in both. To top it off, we bring the best of the best in digital strategy.',
    },
    {
      name: 'Nadeem Haq',
      imageScr: NadeemHaqImg,
      role: 'CTO',
      roleDescription: 'TECHNOLOGY',
      profileUrl: 'https://www.linkedin.com/in/nadeem-haq-ba3823/',
      description:
        "Great architecture isn't about crafting the most complex widget, it's about connecting the dots with clarity and leveraging technology to present choices that make complexity look simple.",
    },
  ];

  function _renderPage() {
    if (width > _sliderMinWindowWidth) {
      return _renderList();
    } else {
      return _renderSlider();
    }
  }

  function _renderList() {
    return _founders.map((founder, index) => _renderFounder(founder, index));
  }

  function _renderSlider() {
    return (
      <Slider<FounderCardProps>
        description='founder'
        list={_founders}
        initialSlideIndex={0}
        renderSlide={(founder, index) => _renderFounder(founder, index)}
      />
    );
  }

  function _renderFounder(founder: FounderCardProps, index: number) {
    return (
      <div className='founder' key={index}>
        <FounderCard
          name={founder.name}
          imageScr={founder.imageScr}
          role={founder.role}
          roleDescription={founder.roleDescription}
          profileUrl={founder.profileUrl}
          description={founder.description}
          quote={founder.quote}
        />
      </div>
    );
  }

  return (
    <div className='founders' id={screenId}>
      <div className='founders__content'>
        <h3>
          Our <b>Leadership</b>
        </h3>
        <div className='founders__list'>{_renderPage()}</div>
      </div>
    </div>
  );
};
