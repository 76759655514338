import React from 'react';
import { HeaderTheme } from '../Header';
import { HomeNavSection } from './HomeNav';
import { LinkButton } from '../Button/LinkButton';
import './MobileHomeNav.scss';

type MobileHomeNavProps = {
  theme: HeaderTheme;
  currentActiveSection: number;
  sections: Array<HomeNavSection>;
  letsTalkScreenId: string;
  onClickAtSection: () => void;
};

export const MobileHomeNav: React.FC<MobileHomeNavProps> = ({
  theme,
  currentActiveSection,
  sections,
  letsTalkScreenId,
  onClickAtSection,
}) => {
  function _renderSections() {
    let mobileSections = [...sections];
    mobileSections.pop();

    return mobileSections.map((section, index) => (
      <li key={index}>
        <a
          className={`${theme} ${index === currentActiveSection ? 'active' : 'inactive'}`}
          href={`#${section.id}`}
          onClick={() => onClickAtSection()}>
          {section.name}
        </a>
      </li>
    ));
  }

  function _renderContactUs() {
    return (
      <div className='lets-talk' onClick={() => onClickAtSection()}>
        <LinkButton
          text='Lets talk!'
          url={`#${letsTalkScreenId}`}
          target='_self'
          font='regular'
          fill='hollow'
          color={theme === 'light' ? 'white' : 'secondary'}
        />
      </div>
    );
  }

  return (
    <nav className={`mobile-navbar ${theme}`}>
      <div className='content'>
        <ul>{_renderSections()}</ul>
        <div className='info'>
          {_renderContactUs()}
          <p>© 2025 C4Workx. All rights reserved</p>
        </div>
      </div>
    </nav>
  );
};
