import React from 'react';
import { LinkedInIcon } from '../../assets';
import './FounderCard.scss';

export type FounderCardProps = {
  name: string;
  imageScr: string;
  role: string;
  roleDescription: string;
  description: string;
  profileUrl: string;
  quote?: string;
};

export const FounderCard: React.FC<FounderCardProps> = ({
  name,
  imageScr,
  profileUrl,
  role,
  roleDescription,
  description,
  quote,
}) => {
  return (
    <div className='founder_card'>
      <img className={`rounded-image`} src={imageScr} alt={description} />
      <div className='infos'>
        <a className='profile' href={profileUrl} target='_blank' rel='noopener noreferrer'>
          <h4>{name}</h4>
          <img className='linkedin-icon' src={LinkedInIcon} alt='LinkedIn profile' />
        </a>
        <p className='role'>{role}</p>
        <p className='roleDescription'>{roleDescription}</p>
        <p className='description'>{description}</p>
        {quote && <p className='quote'>{quote}</p>}
      </div>
    </div>
  );
};
